import { downloadSessionDocument, DownloadSessionDocumentParams, downloadSessionZipDocument } from '../../api/document';

const apiUrl = import.meta.env.VITE_API_URL;

export const downloadDocumentByType = async (
  documentType: string,
  documentName: string | null = null,
  locationUid?: string,
) => {
  try {
    const fileResult = await downloadSessionDocument(documentType, locationUid);

    if (documentName) {
      fileResult.fileName = documentName;
    }

    createDownloadElement(fileResult);
  } catch (exc: any) {
    throw new Error(exc);
  }
};

export const downloadZipDocument = async (downloadSessionDocumentParams: DownloadSessionDocumentParams[]) => {
  try {
    const fileResult = await downloadSessionZipDocument(downloadSessionDocumentParams);
    
    if (!fileResult.fileName) {
      fileResult.fileName = 'Documents.zip';
    }

    createDownloadElement(fileResult);
  } catch (exc: any) {
    throw new Error(exc);
  }
};

export const useDownloadDocument = () => {
  //Redirect to Document Download Endpoint if using link code
  var uri = window.location.toString();
  if (uri.indexOf('Download') > 0) {
    var linkCode = uri.substring(uri.lastIndexOf('/') + 1, uri.length);
    window.location.href = `${apiUrl}/Document/${linkCode}`;
  }
};

export const downloadFunctions = {
  downloadDocumentByType,
  downloadZipDocument,
  useDownloadDocument,
};

function createDownloadElement(fileResult: any) {
  const fileURL = URL.createObjectURL(fileResult.blob);
  const link = document.createElement('a');

  link.href = fileURL;
  link.download = fileResult.fileName;
  link.click();
}

import { apiFunctions } from '..';

const apiUrl = '/Document';

export type DownloadSessionDocumentParams = {
  documentType: string;
  locationUid: string;
  fileName: string;
};

export async function downloadSessionDocument(documentType: string, locationUid?: string) {
  return await apiFunctions.apiPost(
    apiUrl + '/DownloadSessionDocument',
    {
      locationUid,
      documentType,
    },
    null,
    true,
  );
}

export async function downloadSessionZipDocument(downloadSessionDocumentParams: DownloadSessionDocumentParams[]) {
  return await apiFunctions.apiPost(
    apiUrl + '/DownloadSessionZipDocument',
    { downloadSessionDocumentParams },
    null,
    true,
  );
}
